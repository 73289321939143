// 1. Import `ChakraProvider` and `extendTheme`
import { ChakraProvider, extendTheme, ColorModeScript } from '@chakra-ui/react';
import { FaLinkedin, FaTwitter, FaInstagram } from 'react-icons/fa';
import Home from './pages/Home.js';

// 2. Extend the theme to include custom colors, fonts, etc.
const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  // You can customize your theme colors here
  colors: {
    // Define your color overrides here
  },
  components: {
    // Override component styles here
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      {/* ColorModeScript component to manage the color mode */}
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Home />
    </ChakraProvider>
  );
}

export default App;
