import React from 'react';
import {
  Flex,
  VStack,
  Link,
  Heading,
  Text,
  Container,
  UnorderedList,
  ListItem,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { FaLinkedin, FaTwitter, FaInstagram } from 'react-icons/fa';

function Home() {
  return (

    <Flex flexDirection="column" textAlign="center" alignItems="center" bg="blackAlpha.900" color="whiteAlpha.700" minH="100vh">
      {/* About Section */}
      <Container maxW="container.md" mt={2}>
        <VStack spacing={5}>
          <Heading as="h2" size="lg">sam</Heading>
          <Text fontSize="lg">welcome to my website 😎</Text>
        </VStack>
      </Container>

      {/* Current Section */}
      <Container maxW="container.md" mt={2}>
        <VStack spacing={3}>
          <Heading as="h2" size="lg">facts</Heading>
          <UnorderedList textAlign="left">
            <ListItem>study mathematics and computer science at the university of california, berkeley</ListItem>
            <ListItem>interests: software engineering, applied mathematics, tennis, music, etc</ListItem>
          </UnorderedList>
        </VStack>
      </Container>

      {/* Past Section */}
      <Container maxW="container.md" mt={2}>
        <VStack spacing={3}>
          <Heading as="h2" size="lg">past</Heading>
          <UnorderedList textAlign="left">
            <ListItem>
              founding team at <Link href="https://mercuryaio.com" color="orange.500" isExternal>Mercury</Link> with <Link href="https:/nate-wong.com" color="teal.500" isExternal>Nate</Link> and others from 2018-2021, building SaaS that automated the process of acquiring inventory for small business owners. grossed around ~$150k revenue. made a ton of mistakes, but was a great learning experience!
            </ListItem>
            <ListItem>
              software engineering intern at Cisco during summer 2021. made a really sick dashboard🔥🔥🔥🔥😎
            </ListItem>
          </UnorderedList>
        </VStack>
      </Container>

      {/* Current Section */}
      <Container maxW="container.md" mt={2}>
        <VStack spacing={3}>
          <Heading as="h2" size="lg">coursework (updated for sp24)</Heading>
          <UnorderedList textAlign="left">
            <ListItem>CS 61A: The Structure and Interpretation of Computer Programs</ListItem>
            <ListItem>CS 61B: Data Structures</ListItem>
            <ListItem>CS 61C: Great Ideas in Computer Architecture (Machine Structures)</ListItem>
            <ListItem>CS 186: Introduction to Database Systems</ListItem>
            <ListItem>CS 70: Discrete Mathematics and Probability Theory</ListItem>
            <ListItem>Math 53: Multivariable Calculus</ListItem>
            <ListItem>Math 54: Linear Algebra and Differential Equations</ListItem>
          </UnorderedList>
        </VStack>
      </Container>

      {/* Social Media Section */}
      <Container maxW="container.md" mt={2}>
        <VStack spacing={3}>
          <Heading as="h2" size="lg">connect</Heading>
          <HStack spacing={5}>
            <IconButton as={Link} isExternal href="https://www.linkedin.com/in/samsure" aria-label="LinkedIn" icon={<FaLinkedin />} />
            <IconButton as={Link} isExternal href="https://twitter.com/gt3laneswitch" aria-label="Twitter" icon={<FaTwitter />} />
            <IconButton as={Link} isExternal href="https://www.instagram.com/samsure" aria-label="Instagram" icon={<FaInstagram />} />
          </HStack>
          <Text>also, feel free to email at sams@berkeley.edu</Text>
        </VStack>
      </Container>
    </Flex>
  );
}

export default Home;
